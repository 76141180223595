<script>
import Layout from "../../layouts/main";
import {GetDataMethods, notificationMethods} from "@/state/helpers";
import BarChart from "@/views/pages/customer/BarChartIdsCustomers.vue";
import PricingAnalytics from "@/views/pages/orders/pricingAnalytics.vue";

/**
 * Orders Component
 */
export default {
  components: {
    PricingAnalytics,
    BarChart,
    Layout,
  },
  data() {
    return {
      messagTCountOrders:4444,
      messagT: "",
      labelsIds: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "9",
      ],

      dataIds: [65, 65, 65, 45, 56, 80, 50, 20, 0, 0, 50],

      labelsMessages: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "9",
      ],

      dataMessages: [65, 65, 65, 45, 56, 80, 50, 20, 0, 0, 50],
      v: false,
      barChart: {
        series: [{
          data: [380, 430, 450, 475, 550, 584, 780, 1100, 1220, 1365]
        }],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            }
          },
          plotOptions: {
            bar: {
              horizontal: true,
            }
          },
          dataLabels: {
            enabled: false
          },
          colors: ['#34c38f'],
          grid: {
            borderColor: '#f1f1f1',
          },
          xaxis: {
            categories: [],
          }
        }
      },
    };
  },
  methods: {
    ...GetDataMethods,
    ...notificationMethods,
    getDataOrdersUnits() {
      this.getData("get_count_orders_pricing").then((res) => {
        this.labelsIds = res.data.data.count_orders.labels
        this.dataIds = res.data.data.count_orders.data

      })
    },
    getDataOrdersMessages() {
      this.getData("get_count_orders_messages").then((res) => {
        this.labelsMessages = res.data.data.count_orders.labels
        this.dataMessages = res.data.data.count_orders.data
        this.messagT = res.data.data.message_t
        this.messagTCountOrders = res.data.data.message_t_count_orders
        this.v = false
        this.barChart.series = [{
          data: res.data.data.count_orders.data
        }]
        this.barChart.chartOptions.xaxis.categories = res.data.data.count_orders.labels

        this.v = true
      })
    },


  },
  mounted() {
    this.getDataOrdersUnits()
    this.getDataOrdersMessages()
  }

};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-xl-12">
        <PricingAnalytics class="h-auto"/>
      </div>

    </div>
    <div class="row">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">

            <h4 class="card-title mb-4">orders units pricing</h4>
            <!-- Bar Chart -->
            <BarChart title="Count Order" :labels="labelsIds" :data="dataIds"/>
          </div>
        </div>
      </div>

    </div>

    <div class="row h-100">
      <div class="col-lg-12">

        <div class="card">
          <div class="card-body">

            <h4 class="card-title mb-4">orders Messages Template</h4>
            <!-- Bar Chart -->
            <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
            <!--            <PieChart :labels="labelsMessages" :data="dataMessages" :height="140" />-->

            <div>
              <h6  class="ml-5 text-info">Message Template: {{messagT}} - count orders: {{ messagTCountOrders}} </h6>

              <div id="line-column-chart" class="apex-charts" dir="ltr">
              <apexchart
                  v-if="v"
                  class="apex-charts"
                  height="1800"
                  type="bar"
                  dir="ltr"
                  :series="barChart.series"
                  :options="barChart.chartOptions"
              ></apexchart>
              </div>
            </div>
            <!--            <BarChart dir="ltr" title="Count Order" :labels="labelsMessages" :data="dataMessages"/>-->
          </div>
        </div>
      </div>

    </div>


  </Layout>
</template>