<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props:{
    labels : {
      default: [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ]
    },
    data : {
      default : [65, 59, 81, 45, 56, 80, 50, 20]
    },
    title:{
      default : "Count Customer"
    }
  },
  watch:{

    labels: function () { // watch it
      this.render()
    },
    data: function () { // watch it
      this.render()
    },
  },
  methods:{
    render(){
      this.renderChart(
          {
            labels: this.labels,
            datasets: [
              {
                barPercentage: 0.4,
                label: this.title,
                backgroundColor: "rgba(28, 187, 140, 0.8)",
                borderColor: "rgba(28, 187, 140, 0.8)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(28, 187, 140, 0.9)",
                hoverBorderColor: "rgba(28, 187, 140, 0.9)",
                data: this.data
              }
            ]
          },
          {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)"
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    color: "rgba(166, 176, 207, 0.1)"
                  }
                }
              ]
            }
          }
      );
    }
  },
  mounted() {

  }
};
</script>